import React from 'react'
import join from '../assets/join.svg'


function Join() {
    return (
        <div className='flex flex-col items-center z-[3]'>
            <img src={join} alt="" />
        </div>
    )
}

export default Join